
























































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Table, TableColumn } from "element-ui";
import {
  MetaModule as metaStore,
  UserRegistrationModule as userStore,
} from "@/store/modules";
import helpers from "../../utils/helpers";
import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
  },
})
export default class UserRegistrationClass extends Vue {
  userList: any[] = [];
  filter: {
    page: number;
    name?: string;
    role?: string;
  } = {
    page: 1,
    role: "system_user",
  };

  get AdminRouter() {
    return AdminRouter;
  }

  async created() {
    metaStore.loadUserTypesDD();
    await userStore.loadAllSystemUsers(this.filter);
    this.userList = userStore.userList;
  }

  get userRoles() {
    return metaStore.userTypeList;
  }

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  async filterUsers() {
    await userStore.loadAllSystemUsers(this.filter);
    this.userList = userStore.userList;
  }
  async clearFilter() {
    this.filter = {
      page: 1,
      role: "system_user",
    };
    await userStore.loadAllSystemUsers(this.filter);
    this.userList = userStore.userList;
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    await userStore.loadAllSystemUsers(this.filter);
    this.userList = userStore.userList;
  }
}
